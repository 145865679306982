import { Container, Grid } from "@mui/material";
import "./officialInfo.scss";

export default function OfficialInfo() {
  return (
    <div className="officialInfo">
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <h1>Training Info</h1>
            <p><b>New officials</b> are expected to attend 3 hours of classroom training as well as 3 hours of field training to enforce the techniques learned in the classroom.  If you are interested in signing up for training as a new referee, please contact board member Bill Hamann at <a href="mailto:billhamann40@gmail.com">billhamann40@gmail.com</a> and Holly Souza at <a href="mailto:hollycape24@gmail.com">hollycape24@gmail.com</a></p>
            <p><b>Experienced High School officials</b> are expected to attend 3 hours of classroom training as well as 3 hours of field training to enforce the techniques learned in the classroom. Here are the requirements for 2025:</p>
            <ul>
              <li>Email Tim Likes (<a href="mailto:chevylikscape@hotmail.com">chevylikscape@hotmail.com</a>) for Arbiter Set Up information</li>
              <li>Attend classroom training (3 Hrs) - see date below</li>
              <li>Attend field training (3-4 Hrs) - see date below</li>
              <li>Pay MLUA dues</li>
              <li> Register with the Minnesota State High School League</li>
            </ul>
            <h2>Youth Certification Requirements</h2>
            <p>In order to officiate youth games, you must be a certified official. To become certified, you must complete the following requirements:</p>
            <ul>
              <li>Email Tim Likes (chevylikscape@hotmail.com) for Arbiter Set Up information</li>
              <li>Attend classroom training (3 Hrs)</li>
              <li>Attend field training (3-4 Hrs)</li>
              <li>USA Lacrosse Membership Sign-Up</li>
              <li>Pay MLUA Dues</li>
              <li>Complete 2025 youth exam on USA Lacrosse website.</li>
              <li>Understand youth play scenarios</li>
              <li>Work with MLUA Mentor(s) to be observed & get questions answered</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={4}>
            <h2>Important Dates</h2>
            <ul>
              <li>Saturday, January 25: Youth Training</li>
              <li>Sunday, February 2: Youth Training</li>
              <li>Sunday, March 9: High School Training</li>
              <li>Sunday, March 16: High School Games will be assigned</li>
              <li>Wednesday, April 2: MLUA Annual Spring Meeting/Training location TBA</li>
              <li>Saturday and Sunday, April 5 & 6: On field Scrimmages and Training location TBA</li>
              <li>Monday, April 10: MSHL Test Due and games start</li>
              <li>Wednesday, June 11: MLUA End of Season Meeting</li>
              <li>1<sup>st</sup> Monday of Every Month: MLUA Board Meetings</li>
            </ul>
            <h2>2025 Game Fees</h2>
            <ul>
              <li>Youth Games:</li>
                <ul>
                  <li>2 Officials: $60</li>
                  <li>1 Adult Official: $90</li>
                </ul>
              <li> High School JV/B/9 th Grade:</li>
                <ul>
                  <li>2 Officials: $61</li>
                  <li>1 Official: $71</li>
                </ul>
              <li> High School Varsity:</li>
                <ul>
                  <li>3 Officials: $93</li>
                  <li> 2 Officials: $113</li>
                  <li>Travel fees for non-metro games</li>
                </ul>
            </ul>
          </Grid>
        </Grid>
      </Container>
      {/* <Container maxWidth="xl" sx={{ bgcolor: '#FABE2C', padding: 4 }} style={{ marginTop: 25 }}>
        <Container maxWidth="md">
          <h1 className="white" style={{ textAlign: "center" }}>2023 Summer Ref Training</h1>
          <p>Join us for our 2023 summer referee training. We will be hosting a series of training sessions to help you become a better referee. We will cover everything from the basics to advanced techniques. We will also have a special guest speaker who will be sharing their experiences and tips for success. Don't miss out on this great opportunity to improve your skills and become a better referee.</p>
        </Container>
      </Container> */}
    </div>
  );
}