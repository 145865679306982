import { Container } from "@mui/material";
import BoardMember from "./boardMember";

import kip from "../../images/board/kipKilen.jpg"
import tim from "../../images/board/timLIkes.png"
import benton from "../../images/board/bentonCampbell.jpg"
import steve from "../../images/board/steveKudebeh.jpg"
import holly from "../../images/board/Holly.jpg"
import bill from "../../images/board/bill.jpg"
import scott from "../../images/board/Scott.jpeg"

const members = [
  {
    name: "Holly Souza",
    bio: "MLUA Board Chair. (Term ends 2026).", 
    email: "hollycape24@gmail.com",
    picture: holly,
  },
  {
    name: "Steve Kudebeh",
    bio: "MLUA Vice-Chair (Term ends 2025)",
    email: "Kudebeh_steve@yahoo.com",
    picture: steve,
  },
  {
    name: "Tim Likes",
    bio: "MLUA Treasurer (Term ends 2025). Also serves as the Coordinator for Referee development for U.S. Lacrosse in 7 states.",
    eamil: "chevylikscape@hotmail.com",
    picture: tim,
  },
  {
    name: "Benton Campbell",
    bio: "MLUA Secretary (Term ends 2025).",
    email: "bentonc7@gmail.com",
    picture: benton,
  },
  {
    name: "Bill Hamann",
    bio: "MLUA Member at Large (Term ends 2026).",
    email: "billhamann40@yahoo.com",
    picture: bill,
  },
  {
    name: "Scott Reed",
    bio: "MLUA Member at Large (Term ends 2025).",
    email: "leinereed@gmail.com",
    picture: scott,
  },
  {
    name: "Kip Kilen",
    bio: "MLUA Member at Large. (Term ends 2026).", 
    email: "coachkk2@gmail.com",
    picture: kip,
  },
]

export default function Board() {
  return (
    <Container maxWidth="lg">
      <h1>Meet the Board</h1>
      {members.map((member) => {return <BoardMember {...member} />})}
    </Container>

  );
}