import { Container, Grid } from '@mui/material';
import './memberInfo.scss';
import ref from '../../images/BlakeEPPlayerPic.jpg'

export default function MemberInfo() {
  return (
    <div className="memberInfo">
      <Container maxWidth="lg">
        <h1>Member Information</h1>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <h2>MLUA Policies & Procedures</h2>
            <p>The MLUA Policies & Procedures contain guidelines on dues, official & travel fees, game delays, professionalism, game formats, required uniform, assigning games, umpire suspension/probation, ethical code of conduct, grievance policies, & rating requirements.</p>

            <h2>MLUA ANNUAL MEMBERSHIP DUES:</h2>
            <p>2025 Dues: Dues are due no later than April 2, 2025. You will not be assigned
                games/games will be reassigned if your dues are not paid. You can either Venmo Tim at <a href="https://account.venmo.com/u/timothy-mlua" target="_blank" rel="noreferrer">@Timothy-MLUA</a> or send a check to: <br /><br />
                Tim Likes<br />
                2950 ST E<br />
                Hastings, MN 55033
            </p>
            <ul>
              <li>$45 for Youth-Only Officials</li>
              <li>$60 for USALacrosse Membership (mandatory for youth officials highly encouraged for high school officials) go to USALacrosse.com to sign up.</li>
              <li>$75 First Year High School Officials</li>
              <li>$105 Returning High School Officials (Covers Youth and High school)</li>
              <li>Minnesota State High School League Dues for new and second-year
              officials: $30 for the first sport, and $15 for each additional sport</li>
              <li>Minnesota State High School League Dues for returning officials: $70 for
              the first sport, and $20 for each additional sport</li>
              <li>$30 for referee starter kit (flags, cards, whistle shirt) if you need it.</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3}>
            <img src={ref} alt="referee" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" sx={{ bgcolor: '#FABE2C', padding: 4 }}>
        <Container maxWidth="md">
          <h2 className="white" style={{ textAlign: "center" }}>Please ensure you are registered with other required organizations for your season:</h2>
          <ul>
            <li><a href="http://www.uslacrosse.org/" target="_blank" rel="noreferrer">US Lacrosse Membership</a> (all officials)</li>
            <li><a href="https://app.arbitersports.com/registration/official?org=1709&role=3" target="_blank" rel="noreferrer">MSHSL Membership</a> (for those officiating High School games)</li>
          </ul>
        </Container>
      </Container>
    </div>
  );
}