import { Container, Grid } from "@mui/material"
import mslax from "../../images/mslax.jpeg"
import mshsl from "../../images/mshsl.png"

export default function Resources() {
  return (
    <Container maxWidth="lg">
      <h1>Resources</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <a href="https://www.mslax.net/" target="_blank" rel="noreferrer"><h3>Minnesota Schoolgirls Lacrosse Association</h3></a>
          <p>MSLax's mission is to promote the growth of girls lacrosse in Minnesota.  They work to foster a positive environment to teach skills to players and coaches.  They are the governing body for girls lacrosse in Minnesota currently serving girls in 3rd to 8th grade.</p>
          <a href="https://www.mlua.org/s/MSLax-Coach-and-Referee-Expectations.pdf" target="_blank" rel="noreferrer">MSLax Coach and Referee Expectations</a>
        </Grid>
        <Grid item xs={12} md={3}>
          <img src={mslax} alt="mslax" style={{ width: "100%", marginTop: "15px" }} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} md={3}>
          <img src={mshsl} alt="mslax" style={{ width: "100%", marginTop: "15px"  }} />
        </Grid>
        <Grid item xs={12} md={9}>
          <a href="http://mshsl.arbitersports.com/front/103385/Site" target="_blank" rel="noreferrer"><h3>Minnesota State High School League</h3></a>
          <p>The MSHSL is the sanctioned organization which provides competitive, equitable and uniform opportunities for high school students to learn valuable lessons through participation in athletics and fine arts. The League also provides support for member schools with programs that address sportsmanship, chemical health, scholarship recognition.</p>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} md={9}>
          <a href="http://mshsl.arbitersports.com/front/103385/Site" target="_blank" rel="noreferrer"><h3>Great Northern Lacrosse League</h3></a>
          <p>The Great Northern Lacrosse League (GNLL) provides a fun, safe and supportive environment where area high school and youth players can compete, learn and grow.</p>
        </Grid>
        <Grid item xs={12} md={3}>
          
        </Grid>
      </Grid>
    </Container>
  );
}