import { Container } from "@mui/material";
import './uslLinks.scss';

export default function UslLinks() {
  return (
    <Container maxWidth="lg" className="usl">
      <h1>USL Links</h1>
      <h4>Following are helpful US Lacrosse links for general lacrosse information.  Check it out!</h4>
      <ul>
        <li><a href="http://www.uslacrosse.org/officials" target="_blank" rel="noreferrer">US Lacrosse</a> (USL) website home page - information surrounding men's, women's, youth & administrator lacrosse, membership renewal, & national events. </li>
      </ul>
    </Container>
  );
}