import { Grid, Container } from "@mui/material";
import './home.scss';

export default function Home() {
  return (
    <div className="home">
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <h1>We Are The Minnesota Lacrosse Umpires Association</h1>
          <p>MLUA is a not-for-profit membership organization that provides trained and qualified officials for all levels of girls' and women's lacrosse in the upper midwest area of the United States. We service high school and youth lacrosse in the state of Minnesota as well as college lacrosse games across the upper midwest (including Minnesota, Wisconsin, North Dakota, South Dakota, and Iowa).  All of our umpires are members of US Lacrosse and are trained and rated in accordance with US Lacrosse guidelines for the Women's Division.</p>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <ul>
            <li><p><strong>Want to become a women's lacrosse official?</strong> Please contact Board Chair Holly
Souza (<a href="mailto:hollycape24@gmail.com">hollycape24@gmail.com</a>) if you're interested in becoming a new official (as of January 2025).</p></li>
            <li><p><strong>Want to learn more about being a member of MLUA?</strong> Navigate to MLUA Member Info to find information on training, officiating fees, field rating information and more</p></li>
            <li><p><strong>Looking to learn more about women's and girl's lacrosse?</strong> Use the Additional Resources to find helpful, related girl's & women's lacrosse links.</p></li>
          </ul>
        </Grid>
      </Container>
      {/* To remove the commented coded and make an orange block remove the {/* tags */}
      {/* <Container maxWidth="xl" sx={{ bgcolor: '#FABE2C', padding: 4 }}>
        <Container maxWidth="md">
          <h1 className="white" style={{ textAlign: "center" }}></h1>
          <p></p>
        </Container>
      </Container> */}
    </div>
  );
}